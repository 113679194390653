import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import Project from '../components/Project';

const DivWrapper = styled('div')<{ component?: React.ElementType}>(
  ({ theme }) => ({
    backgroundColor: theme.palette.body.backgroundColor,
    color: theme.palette.body.color,
    textAlign: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: 20
}));

export default function Projects() {
  const projects = [
    {
      siteName: "Fatherly Funnies",
      siteDescription: "A website to display a work appropriate daily joke to make your day a little bit brighter",
      level: "PREMIUM",
      url: "https://fatherlyfunnies.com",
      technologies: ["Nuxt", "Vue", "Vuetify", "Java/Spring Boot", "MongoDB"]
    },
    {
      siteName: "MD FOP Golf Classic",
      siteDescription: "Registration site for the annual FOP Lodge #102 Fall Charity Golf Classic.",
      level: "PREMIUM",
      url: "https://mdfop102golf.com",
      technologies: ["ReactJS", "Mui", "Java/Spring Boot", "MongoDB"]
    },
    {
      siteName: "Culper Technology",
      siteDescription: "Culper Technology is committed to providing technical solutions that enable a better everyday life for users throughout the U.S. Department of Defense and broader Intelligence Community using years of subject-matter expertise and technical acumen to achieve mission goals.",
      level: "BASIC",
      url: "https://culpertechnology.com",
      technologies: ["Wix"]
    },
    // {
    //   siteName: "Red Wolf Projects",
    //   siteDescription: "A basic version of our Red Wolf Projects Website. This one includes a contact form as well as a joke on every business day in case you need a laugh.",
    //   level: "BASIC",
    //   url: "https://redwolfprojectsinf.wixsite.com/redwolfprojects"
    // }
  ]

  return (
    <DivWrapper>
      <Typography variant='h1'>
        Check out some of our other projects
      </Typography>
      <hr style={{ marginBottom: 15 }} />
      <Grid container direction="row">
        <Grid item>
          <Grid container justifyContent={'space-between'}>
            {
              projects.map((p) => {
                return (
                  <Grid item xs={projects.length < 2 ? 8 : 4} key={p.siteName} >
                    <Project {...p} />
                  </Grid>

                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </DivWrapper >
  )
}